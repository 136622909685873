import React from "react"
import SEO from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"
import HeroBanner from "../components/home/heroBanner/HeroBanner"
import { Container } from "reactstrap"
import TitleBannerEnquiry from "../components/titleBanner/TitleBannerEnquiry"

let FAQ = () => {
    return (
        <>
            <SEO
                title="BIM & CAD Training Center | Ace Industrial Academy"
                description="Ace Industrial Academy is a leading training center in Singapore for IT courses, BIM courses, 3D CAD courses, 4D courses, software courses, building information modeling courses and more."
            />
            <TitleBannerEnquiry
                title="Frequently Asked Question"
                description=""
                withOnlyDescription
                hasPlainBanner
            />

            <Container fluid className="pt-6" style={{ backgroundColor: '' }}>
                <Container className="pt-4"><br />
                    <h3 className="trainingTitle">What will I learn?</h3>
                    <p className="trainingContent">
                        <br />
                        •	Each course page has a course overview and a set of learning outcomes.
                        <br /><br />
                        •	Learners are strongly advised to understand the course overview and learning outcomes to determine the course’s relevance to their employment.
                        <br /><br />
                        •	If you require more clarification, learners may reach us at <a href="mailto:training@aia.edu.sg">training@aia.edu.sg</a> or submit an enquiry form.
                        <br /><br />
                    </p><br /><br />
                </Container>
            </Container>

            <Container fluid className="pt-6" style={{ backgroundColor: '#f1f6ff' }}>
                <Container className="pt-4">
                    <h3 className="trainingContent mt-3">What are the entry requirements?</h3><br />
                    <p className="trainingContent">
                        •	Each course page has a set of entry requirements.
                        <br /><br />
                        •	Learners who are applying for funding from Skillsfuture must meet all entry requirements of the course they are applying for.
                        <br /><br />
                        •	Learners who are not applying for funding shall minimally meet the work experience or any equivalent course requirements to help you understand the topics in class.
                        <br /><br />
                    </p><br /><br />
                </Container>
            </Container>

            <Container fluid className="pt-6" style={{ backgroundColor: '' }}>
                <Container className="pt-4">
                    <h3 className="trainingContent mt-3">What is the attendance requirement? </h3><br />
                    <p className="trainingContent">
                        •	We strongly advise that learners meet at least 75% of the attendance requirements to keep up with the learnings in class and be better positioned to pass the assessment.
                        <br /><br />
                    </p><br /><br />
                </Container>
            </Container>

            <Container fluid className="pt-6" style={{ backgroundColor: '#f1f6ff' }}>
                <Container className="pt-4">
                    <h3 className="trainingContent mt-3">What are the course completion requirements?</h3><br />
                    <p className="trainingContent">
                        •	Complete and pass the assessment requirements.
                        <br /><br />
                    </p><br /><br />
                </Container>
            </Container>

            <Container fluid className="pt-6" style={{ backgroundColor: '' }}>
                <Container className="pt-4">
                    <h3 className="trainingContent mt-3">What if I do not pass the assessment?</h3><br />
                    <p className="trainingContent">
                        •	You may choose to re-take the assessment. Each re-assessment is subject to a fee of $100 (before GST).
                        <br /><br />
                        •	If you do not meet the course completion requirements, Course Fee Funding from SkillsFuture Singapore is no longer applicable. As such, learners or employer (where applicable) will be responsible for paying the balance of the full course fee.
                        <br /><br />
                    </p><br /><br />
                </Container>
            </Container>

            <Container fluid className="pt-6" style={{ backgroundColor: '#f1f6ff' }}>
                <Container className="pt-4">
                    <h3 className="trainingContent mt-3">What certificate will I get?</h3><br />
                    <p className="trainingContent">
                        •	For WSQ-accredited courses, learners will be conferred a WSQ certificate for meeting all course completion requirements.
                        <br /><br />
                        •	For non-WSQ-accredited courses, learners who meet all course completion requirements will be accorded a Certificate of Competence from Ace Industrial Academy.
                        <br /><br />
                    </p><br /><br />
                </Container>
            </Container>

            <Container fluid className="pt-6" style={{ backgroundColor: '' }}>
                <Container className="pt-4">
                    <h3 className="trainingContent mt-3">What are the fundings available?</h3><br />
                    <p className="trainingContent">
                        •	<a href="https://www.aia.edu.sg/funding">Course fee fundings</a>  are available to <a href="https://www.aia.edu.sg/funding">self-sponsored and/or company-sponsored  participants.</a>
                        <br /><br />
                        •	Self-sponsored participants can further use their SkillsFuture Credit to offset the nett course fees are course fee fundings.
                        <br /><br />
                        •	Employers of company-sponsored participants are further eligible for absentee payroll funding, and can use the SkillsFuture Enterprise Credit to offset nett course fees.
                        <br /><br />
                        •	Singapore Citizen students or students with Singapore Citizen siblings may be eligible to use <a href="https://www.aia.edu.sg/PSEA">Post-Secondary Education Account (PSEA)</a> funds to offset course fees.
                        <br /><br />
                        •	If you need clarification on funding matters, please reach us at <a href="mailto:training@aia.edu.sg">training@aia.edu.sg</a> or submit an enquiry form .
                        <br /><br />
                    </p><br /><br />
                </Container>
            </Container>

            <Container fluid className="pt-6" style={{ backgroundColor: '#f1f6ff' }}>
                <Container className="pt-4">
                    <h3 className="trainingContent mt-3">Why is AIA collecting my NRIC number and birth date?</h3><br />
                    <p className="trainingContent">
                        •	Your full NRIC number is required for us to check your funding eligibility.
                        <br /><br />
                        •	Only AIA’s staff processing your application will request for your NRIC.
                        <br /><br />
                        •	Our staff are listed here: <a href="https://www.aia.edu.sg/theteam/">BIM & CAD Training Center | Ace Industrial Academy | BIM & CAD Training Center</a>.
                        <br /><br />
                        •	You may also refer to our policy on <a href="https://www.aia.edu.sg/schedule/">personal data protection.</a>
                        <br /><br />
                    </p><br /><br />
                </Container>
            </Container>

            <Container fluid className="pt-6" style={{ backgroundColor: '' }}>
                <Container className="pt-4">
                    <h3 className="trainingContent mt-3">What is the process for funding application?</h3><br />
                    <p className="trainingContent">
                        •	For self-sponsored participants (Singaporean/PR), funding will be automatically calculated during the billing process. Participants to ensure the NRIC provided is correct to ensure the funding eligibility.
                        <br /><br />
                        •   For company-sponsored participants (Singaporean/PR), company UEN is to be provided to calculate the funding eligible. SMEs that meets the criteria shall receive additional support in the course subsidies. Funding shall be automatically calculated during the billing process.
                        <br /><br />
                    </p><br /><br />
                </Container>
            </Container>

            {/* <Container fluid className="pt-6" style={{ backgroundColor: '#f1f6ff' }}>
                <Container className="pt-4">
                    <p className="trainingContent" style={{ fontSize: '0.9em' }}>
                        <em>Note: Student, parent (or legal guardian) and siblings must be above 21 years old, have SingPass and email accounts to apply/authorise for MOE-PSEA</em>
                    </p>
                    <br />
                    <p className="trainingContent" style={{ fontSize: '0.9em' }}>
                        <em>A trainee and, if using a sibling’s PSEA, the sibling authoriser must be at least 21 years old (exact to date of birth) at the time of applying. If a trainee or the sibling authoriser is below 21 years old, a parent must apply or authorise on behalf, using the parent’s Singpass.</em>
                    </p>
                    <br />
                    <p className="trainingContent" style={{ fontSize: '0.9em' }}>
                        <em>All PSEA withdrawals will be made from the trainee’s own PSEA first. Withdrawals will only be made from their sibling’s PSEA when there are insufficient funds in the trainee’s own PSEA.</em>
                    </p>
                    <br />
                    <p className="trainingContent" style={{ fontSize: '0.9em' }}>
                        <em>You can call the 24-hour automated PSEA hotline at 6260 0777 to check your balance. Latest PSEA balance information will be updated every Wednesday. To find out more about the <a href="https://www.moe.gov.sg/financial-matters/psea">Post-Secondary Education Account (PSEA) | MOE</a></em>
                    </p>
                    <br />
                </Container>
            </Container> */}
        </>
    )
}
export default FAQ